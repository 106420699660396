import React from "react";

type Length = `${number}${"px" | "em"}`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  size?: Length;
};

export default function IconLive({ className, style, size = "1em" }: Props) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width={size}
      height={size}
    >
      <g clipPath="url(#clip0_6953_6451)">
        <mask
          id="mask0_6953_6451"
          width="32"
          height="32"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path fill="#fff" d="M32 0H0v32h32V0Z" />
        </mask>
        <g mask="url(#mask0_6953_6451)">
          <path
            fill="currentColor"
            d="M6.746 5.373a1.155 1.155 0 0 1 0 1.633 13.089 13.089 0 0 0 0 18.512 1.155 1.155 0 0 1-1.634 1.632c-6.014-6.013-6.014-15.763 0-21.777a1.155 1.155 0 0 1 1.632 0h.002ZM26.89 5.373c6.014 6.014 6.014 15.764 0 21.779a1.154 1.154 0 0 1-1.632-1.633 13.089 13.089 0 0 0 0-18.513 1.155 1.155 0 1 1 1.633-1.633Z"
            opacity=".203"
          />
          <path
            fill="currentColor"
            d="M11.1 9.728a1.156 1.156 0 0 1 0 1.633 6.927 6.927 0 0 0 0 9.802 1.154 1.154 0 0 1-1.632 1.632 9.24 9.24 0 0 1 0-13.067 1.155 1.155 0 0 1 1.632 0ZM22.537 9.728a9.24 9.24 0 0 1 0 13.067 1.154 1.154 0 0 1-1.986-.811 1.151 1.151 0 0 1 .352-.821 6.93 6.93 0 0 0 0-9.8 1.155 1.155 0 0 1 1.632-1.633l.002-.002Z"
            opacity=".711"
          />
          <path
            fill="currentColor"
            d="M16.001 13.952a2.31 2.31 0 1 1 0 4.62 2.31 2.31 0 0 1 0-4.62Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6953_6451">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import ButtonTransparent from "kreate-common/modules/kreate-ui/components/ButtonTransparent";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import {
  MdClose,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import { getAuctionOverview } from "../../../../../../../../containers/PageArtwork/containers/ArtworkDetailsViewer/containers/PanelPrimaryAcquisition/containers/MethodAuctionEnglish/utils";
import BackgroundImage from "../../components/BackgroundImage";
import Layout from "../../components/Layout";
import Symbol from "../../components/Symbol";
import UserViewer from "../../components/UserViewer";
import { GetRibbonsResult$RibbonEnglishAuction } from "../../types";

import styles from "./index.module.scss";

import { useIsExpired } from "@/modules/common-hooks/useIsExpired";
import { BULLET, EM_DASH } from "@/modules/common-utils/unicode";
import CountdownTimer from "@/modules/kreate-components/components/CountdownTimer";
import IconLive from "@/modules/kreate-components/icons/IconLive";
import { getLinkToPageArtwork } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonEnglishAuction;
  onClose?: () => void;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
};

export default function RibbonEnglishAuctionV2({
  className,
  style,
  ribbon,
  onClose,
  onNavigateLeft,
  onNavigateRight,
}: Props) {
  const router = useRouter();
  const artwork = ribbon.artwork;
  const overview = artwork ? getAuctionOverview(artwork) : undefined;
  const isStarted = useIsExpired(overview?.startedAt);
  const isEnded = useIsExpired(overview?.endedAt);

  if (!artwork || !overview) return null;

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Layout.Root theme={ribbon.theme || undefined}>
        <Layout.Background>
          <BackgroundImage backgroundImageKey={ribbon.backgroundImage} fill />
        </Layout.Background>
        <Layout.Group className={styles.groupCategory}>
          <Flex.Row gap="12px" alignItems="center">
            <IconLive className={styles.colorPrimary} size="32px" />
            <div>{"Live English Auction"}</div>
          </Flex.Row>
        </Layout.Group>
        <Layout.Separator className={styles.opacity_16} content={BULLET} />
        <Layout.Group className={styles.groupInfo}>
          <Flex.Row flex="0 1 auto" gap="12px" alignItems="center">
            <UserViewer user={artwork.populated?.createdBy} />
            <Symbol.VerticalLine className={styles.opacity_16} />
            <Flex.Row
              flex="0 0 auto"
              minWidth="0"
              gap="12px"
              alignItems="center"
            >
              <div>
                <em>{"Edition:"}</em>
              </div>
              <div>
                {[
                  overview.numAuctionedEditions || EM_DASH,
                  artwork.totalSupply || EM_DASH,
                ].join("/")}
              </div>
            </Flex.Row>
            <Symbol.Bullet className={styles.opacity_16} />
            <Flex.Cell
              flex="0 1 auto"
              minWidth="0"
              className={styles.artworkTitle}
            >
              <Link href={getLinkToPageArtwork(artwork)}>
                {artwork.title || EM_DASH}
              </Link>
            </Flex.Cell>
          </Flex.Row>
        </Layout.Group>
        <Layout.Space />
        <Layout.Group className={styles.groupActions}>
          {isEnded ? (
            <Flex.Cell>
              <em>{"Auction ended"}</em>
            </Flex.Cell>
          ) : isStarted ? (
            <Flex.Row gap="20px" alignItems="center">
              <Flex.Row gap="8px" alignItems="center">
                <Flex.Cell>
                  <em>{"Bidding closes in:"}</em>
                </Flex.Cell>
                <CountdownTimer
                  as="div"
                  expiredAt={overview.endedAt}
                  unstyled
                />
              </Flex.Row>
              <ButtonPrimary
                content="Place Your Bid"
                onClick={() => router.push(getLinkToPageArtwork(artwork))}
              />
            </Flex.Row>
          ) : (
            <Flex.Row gap="8px" alignItems="center">
              <Flex.Cell>
                <em>{"Coming Soon: "}</em>
              </Flex.Cell>
              <CountdownTimer
                as="div"
                expiredAt={overview.startedAt}
                unstyled
              />
            </Flex.Row>
          )}
        </Layout.Group>
        <Layout.Separator />
        <Layout.Close>
          <Flex.Row>
            <ButtonTransparent
              style={{ color: "currentColor" }}
              icon={<MdClose size="24px" />}
              onClick={onClose}
            />
          </Flex.Row>
        </Layout.Close>
        {!!onNavigateLeft && (
          <Layout.LeftArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowBackIosNew size="24px" />}
              onClick={onNavigateLeft}
            />
          </Layout.LeftArrow>
        )}
        {!!onNavigateRight && (
          <Layout.RightArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowForwardIos size="24px" />}
              onClick={onNavigateRight}
            />
          </Layout.RightArrow>
        )}
      </Layout.Root>
    </div>
  );
}

import cx from "clsx";
import { GetRibbons$Result } from "kreate-server/commands/GetRibbons/typing";
import Image, { StaticImageData } from "next/image";
import React from "react";

import jpgDrei from "./assets/drei.jpg";
import jpgEins from "./assets/eins.jpg";
import jpgFunf from "./assets/funf.jpg";
import jpgSechs from "./assets/sechs.jpg";
import jpgSieben from "./assets/sieben.jpg";
import jpgVier from "./assets/vier.jpg";
import jpgZwei from "./assets/zwei.jpg";
import styles from "./index.module.scss";

export function toStaticImageData(
  key: GetRibbons$Result["ribbons"][number]["backgroundImage"]
): StaticImageData | undefined {
  switch (key) {
    case "eins":
      return jpgEins;
    case "zwei":
      return jpgZwei;
    case "drei":
      return jpgDrei;
    case "vier":
      return jpgVier;
    case "funf":
      return jpgFunf;
    case "sechs":
      return jpgSechs;
    case "sieben":
      return jpgSieben;
  }
  return undefined;
}

type Props = {
  className?: string;
  style?: React.CSSProperties;
  backgroundImageKey: GetRibbons$Result["ribbons"][number]["backgroundImage"];
  fill: true;
};

export default function BackgroundImage({
  className,
  style,
  backgroundImageKey,
}: Props) {
  const src = backgroundImageKey
    ? toStaticImageData(backgroundImageKey)
    : undefined;

  if (!src) return null;

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Image
        style={{ objectFit: "cover" }}
        src={src}
        alt=""
        sizes="100vw"
        fill
      />
    </div>
  );
}

import {
  Artwork,
  Collection,
  AuctionBid,
  BaseHighlightRibbon,
  CuratedCollection,
} from "kreate-common/modules/business-types";
import { z } from "zod";

export const Result = z.object({
  ribbons: z
    .union([
      BaseHighlightRibbon.extend({
        type: z.literal("english-auction"),
        artwork: Artwork.nullish(),
        leadingBid: AuctionBid.nullish(),
      }),
      BaseHighlightRibbon.extend({
        type: z.literal("gacha-minting"),
        collection: Collection.nullish(),
      }),
      BaseHighlightRibbon.extend({
        type: z.literal("curated-collection"),
        collection: CuratedCollection.nullish(),
      }),
      BaseHighlightRibbon.extend({
        type: z.literal("catalyst-voting"),
      }),
    ])
    .array(),
});

export type Result = z.infer<typeof Result>;

export type GetRibbons$Result = Result;
export const GetRibbons$Result = Result;

import cx from "clsx";
import { SimpleUser } from "kreate-common/modules/business-types";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";

import styles from "./index.module.scss";

import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  user: SimpleUser | null | undefined;
};

export default function UserViewer({ className, style, user }: Props) {
  return (
    <Flex.Row
      flex="0 1 auto"
      gap="12px"
      alignItems="center"
      minWidth="0"
      className={cx(styles.container, className)}
      style={style}
    >
      <Flex.Row flex="0 0 auto">
        <AvatarViewer value={user?.avatar} size="32px" />
      </Flex.Row>
      <Flex.Cell flex="0 1 auto" minWidth="0">
        <UserDisplayNameViewerV2 user={user} unstyled />
      </Flex.Cell>
    </Flex.Row>
  );
}

import {
  getResourceKey$GetRibbons,
  httpGet$GetRibbons,
} from "kreate-server/commands/GetRibbons/fetcher";
import {
  getResourceKey$GetSessionPreferences,
  getSessionPreferences,
} from "kreate-server/commands/GetSessionPreferences/fetcher";
import { updateSessionPreferences } from "kreate-server/commands/UpdateSessionPreferences/fetcher";
import React from "react";
import useSWR from "swr";

import { useLoginStatus } from "@/modules/common-hooks/useLoginStatus";

export function useRibbonCarouselApi({
  disabled = false,
}: { disabled?: boolean } = {}) {
  const loginStatus = useLoginStatus();
  const getRibbonsSWR = useSWR(
    !disabled ? getResourceKey$GetRibbons() : undefined,
    async () =>
      !disabled
        ? await httpGet$GetRibbons("/api/v1/highlight/ribbons")
        : undefined
  );

  const sessionPreferencesSWR = useSWR(
    !disabled ? getResourceKey$GetSessionPreferences() : undefined,
    async () => (!disabled ? await getSessionPreferences() : undefined)
  );

  const [index, setIndex] = React.useState(0);
  const ribbonList = getRibbonsSWR.data?.ribbons?.filter(
    (r) => !r.hidden || (loginStatus?.loggedIn && loginStatus.isAgent)
  );
  const numRibbons = ribbonList?.length || 0;
  const activeRibbon = getRibbonsSWR.data?.ribbons[index];

  const navigateLeft =
    numRibbons > 1
      ? () => setIndex((index) => (index + numRibbons - 1) % numRibbons)
      : undefined;

  const navigateRight =
    numRibbons > 1
      ? () => setIndex((index) => (index + 1) % numRibbons)
      : undefined;

  const close = async () => {
    await updateSessionPreferences({
      ...sessionPreferencesSWR.data,
      hideRibbonCarousel_20230825: true,
    });
    await sessionPreferencesSWR.mutate();
  };

  const isClosedByUser =
    sessionPreferencesSWR.data?.hideRibbonCarousel_20230825;

  return {
    isClosedByUser,
    activeRibbon,
    navigateLeft,
    navigateRight,
    close,
  };
}

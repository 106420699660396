import { fromJson } from "kreate-common/modules/json-utils";

import { ResourceKey } from "../../types";

import { Result } from "./typing";

export async function httpGet$GetRibbons(url: string) {
  const response = await fetch(url);
  const text = await response.text();
  const data = fromJson(text);
  const result = Result.parse(data);
  return result;
}

export function getResourceKey$GetRibbons(): ResourceKey {
  return ["5df7ef41-f047-4a2f-bb3d-d89d7efbccb9"];
}

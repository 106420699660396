import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

import { BULLET } from "@/modules/common-utils/unicode";

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
};

function Bullet({ className, style }: BaseProps) {
  return (
    <div className={cx(styles.bullet, className)} style={style}>
      {BULLET}
    </div>
  );
}

function VerticalLine({ className, style }: BaseProps) {
  return (
    <div className={cx(styles.verticalLine, className)} style={style}></div>
  );
}

const Symbol = {
  Bullet,
  VerticalLine,
};

export default Symbol;

import cx from "clsx";
import React from "react";

import RibbonCatalystVotingV2 from "./containers/RibbonCatalystVotingV2";
import RibbonCuratedCollection from "./containers/RibbonCuratedCollection";
import RibbonEnglishAuctionV2 from "./containers/RibbonEnglishAuctionV2";
import RibbonGachaMintingV2 from "./containers/RibbonGachaMintingV2";
import { useRibbonCarouselApi } from "./hooks";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  api: ReturnType<typeof useRibbonCarouselApi>;
};

export default function RibbonCarousel({ className, style, api }: Props) {
  if (api.isClosedByUser || !api.activeRibbon) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)} style={style}>
      {api.activeRibbon == null ? undefined : api.activeRibbon.type ===
        "english-auction" ? (
        <RibbonEnglishAuctionV2
          ribbon={api.activeRibbon}
          onNavigateLeft={api.navigateLeft}
          onNavigateRight={api.navigateRight}
          onClose={api.close}
        />
      ) : api.activeRibbon.type === "gacha-minting" ? (
        <RibbonGachaMintingV2
          ribbon={api.activeRibbon}
          onNavigateLeft={api.navigateLeft}
          onNavigateRight={api.navigateRight}
          onClose={api.close}
        />
      ) : api.activeRibbon.type === "curated-collection" ? (
        <RibbonCuratedCollection
          ribbon={api.activeRibbon}
          onNavigateLeft={api.navigateLeft}
          onNavigateRight={api.navigateRight}
          onClose={api.close}
        />
      ) : api.activeRibbon.type === "catalyst-voting" ? (
        <RibbonCatalystVotingV2
          ribbon={api.activeRibbon}
          onNavigateLeft={api.navigateLeft}
          onNavigateRight={api.navigateRight}
          onClose={api.close}
        />
      ) : null}
    </div>
  );
}

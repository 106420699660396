import cx from "clsx";
import React from "react";

import styles from "./index.module.scss";

const THEME = {
  darkBackground: styles.theme_darkBackground,
  lightBackground: styles.theme_lightBackground,
};

type BaseProps = {
  className?: string;
  style?: React.CSSProperties;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

function Root({
  className,
  style,
  content,
  children = content,
  theme = "darkBackground",
}: BaseProps & {
  theme?: keyof typeof THEME;
}) {
  return (
    <div className={cx(styles.root, className, THEME[theme])} style={style}>
      {children}
    </div>
  );
}

function Background({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div className={cx(styles.background, className)} style={style}>
      {children}
    </div>
  );
}

function Group({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.group, className)} style={style}>
      {children}
    </div>
  );
}

function Space({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.space, className)} style={style}>
      {children}
    </div>
  );
}

function Separator({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div
      className={cx(
        styles.separator,
        className,
        children ? styles.mx_12px : styles.w_20px
      )}
      style={style}
    >
      {children}
    </div>
  );
}

function Close({ className, style, content, children = content }: BaseProps) {
  return (
    <div className={cx(styles.close, className)} style={style}>
      {children}
    </div>
  );
}

function LeftArrow({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div className={cx(styles.leftArrow, className)} style={style}>
      {children}
    </div>
  );
}

function RightArrow({
  className,
  style,
  content,
  children = content,
}: BaseProps) {
  return (
    <div className={cx(styles.rightArrow, className)} style={style}>
      {children}
    </div>
  );
}

const Layout = {
  Root,
  Background,
  Group,
  Space,
  Separator,
  Close,
  LeftArrow,
  RightArrow,
};

export default Layout;

import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import ButtonTransparent from "kreate-common/modules/kreate-ui/components/ButtonTransparent";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import {
  MdClose,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import BackgroundImage from "../../components/BackgroundImage";
import Layout from "../../components/Layout";
import Symbol from "../../components/Symbol";
import { GetRibbonsResult$RibbonGachaMinting } from "../../types";

import styles from "./index.module.scss";

import { useIsExpired } from "@/modules/common-hooks/useIsExpired";
import { EM_DASH } from "@/modules/common-utils/unicode";
import AvatarViewer from "@/modules/kreate-components/components/AvatarViewer";
import CountdownTimer from "@/modules/kreate-components/components/CountdownTimer";
import UserDisplayNameViewerV2 from "@/modules/kreate-components/components/UserDisplayNameViewerV2";
import IconLive from "@/modules/kreate-components/icons/IconLive";
import { getLinkToPageCollection } from "@/modules/navigation/utils/getLinkTo";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonGachaMinting;
  onClose?: () => void;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
};

export default function RibbonGachaMintingV2({
  className,
  style,
  ribbon,
  onClose,
  onNavigateLeft,
  onNavigateRight,
}: Props) {
  const router = useRouter();
  const collection = ribbon.collection;
  const artist = ribbon.collection?.populated?.createdBy;
  const isStarted = useIsExpired(ribbon.collection?.gachaMinting?.startedAt);

  return (
    <div className={cx(styles.container, className)} style={style}>
      <Layout.Root theme={ribbon.theme || undefined}>
        <Layout.Background>
          <BackgroundImage backgroundImageKey={ribbon.backgroundImage} fill />
        </Layout.Background>
        <Layout.Group className={styles.groupCategory}>
          <Flex.Row gap="12px" alignItems="center">
            <IconLive className={styles.colorPrimary} size="32px" />
            <div>
              {collection?.gachaMinting?.editionPrice
                ? "Gacha Mint"
                : "Free Gacha Mint"}
            </div>
          </Flex.Row>
        </Layout.Group>
        <Layout.Separator className={styles.opacity_16}>
          <Symbol.Bullet />
        </Layout.Separator>
        <Layout.Group className={styles.groupInfo}>
          <Flex.Row gap="12px" alignItems="center">
            <Flex.Row flex="0 0 auto">
              <AvatarViewer value={collection?.avatar} size="32px" />
            </Flex.Row>
            <Flex.Cell
              minWidth="0"
              flex="0 1 auto"
              className={styles.collectionName}
            >
              <Link
                href={
                  collection ? getLinkToPageCollection(collection) : "#disabled"
                }
              >
                {collection?.title || EM_DASH}
              </Link>
            </Flex.Cell>
            <Flex.Cell className={styles.opacity_64}>{"by"}</Flex.Cell>
            <Flex.Cell flex="0 1 auto" minWidth="0">
              <UserDisplayNameViewerV2 user={artist} unstyled />
            </Flex.Cell>
            {!!ribbon.collection?.gachaMinting?.description && (
              <Flex.Cell flex="0 0 auto">
                <Symbol.Bullet className={styles.opacity_16} />
              </Flex.Cell>
            )}
            {!!ribbon.collection?.gachaMinting?.description && (
              <Flex.Cell flex="0 0 auto">
                {ribbon.collection?.gachaMinting?.description}
              </Flex.Cell>
            )}
          </Flex.Row>
        </Layout.Group>
        <Layout.Space />
        <Layout.Group className={styles.groupActions}>
          {!isStarted ? (
            <div>
              <Flex.Row gap="8px" alignItems="center">
                <Flex.Cell>
                  <em>{"Starting in"}</em>
                </Flex.Cell>
                <CountdownTimer
                  as="div"
                  expiredAt={ribbon.collection?.gachaMinting?.startedAt}
                  unstyled
                />
              </Flex.Row>
            </div>
          ) : (
            <ButtonPrimary
              content="Purchase Now"
              onClick={() =>
                collection && router.push(getLinkToPageCollection(collection))
              }
            />
          )}
        </Layout.Group>
        <Layout.Separator />
        <Layout.Close>
          <Flex.Row>
            <ButtonTransparent
              style={{ color: "currentColor" }}
              icon={<MdClose size="24px" />}
              onClick={onClose}
            />
          </Flex.Row>
        </Layout.Close>
        {!!onNavigateLeft && (
          <Layout.LeftArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowBackIosNew size="24px" />}
              onClick={onNavigateLeft}
            />
          </Layout.LeftArrow>
        )}
        {!!onNavigateRight && (
          <Layout.RightArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowForwardIos size="24px" />}
              onClick={onNavigateRight}
            />
          </Layout.RightArrow>
        )}
      </Layout.Root>
    </div>
  );
}

import cx from "clsx";
import ButtonPrimary from "kreate-common/modules/kreate-ui/components/ButtonPrimary";
import ButtonTransparent from "kreate-common/modules/kreate-ui/components/ButtonTransparent";
import Flex from "kreate-common/modules/kreate-ui/components/Flex";
import React from "react";
import {
  MdClose,
  MdHowToVote,
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import BackgroundImage from "../../components/BackgroundImage";
import Layout from "../../components/Layout";
import { GetRibbonsResult$RibbonCatalystVoting } from "../../types";

import styles from "./index.module.scss";

import { BULLET } from "@/modules/common-utils/unicode";
import IconLive from "@/modules/kreate-components/icons/IconLive";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  ribbon: GetRibbonsResult$RibbonCatalystVoting;
  onClose?: () => void;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
};

export default function RibbonCatalystVotingV2({
  className,
  style,
  ribbon,
  onClose,
  onNavigateLeft,
  onNavigateRight,
}: Props) {
  return (
    <div className={cx(styles.container, className)} style={style}>
      <Layout.Root theme={ribbon.theme || undefined}>
        <Layout.Background>
          <BackgroundImage backgroundImageKey={ribbon.backgroundImage} fill />
        </Layout.Background>
        <Layout.Group className={styles.groupCategory}>
          <Flex.Row gap="12px" alignItems="center">
            <IconLive className={styles.colorPrimary} size="32px" />
            <div>{"Help build Kreate for everyone!"}</div>
          </Flex.Row>
        </Layout.Group>
        <Layout.Separator className={styles.opacity_16} content={BULLET} />
        <Layout.Group className={styles.groupInfo}>
          <Flex.Row flex="0 1 auto" gap="12px" alignItems="center">
            <div>{"Support our Catalyst Proposal!"}</div>
          </Flex.Row>
        </Layout.Group>
        <Layout.Space />
        <Layout.Group className={styles.groupActions}>
          <ButtonPrimary
            icon={<MdHowToVote />}
            content="Vote Now!"
            onClick={() =>
              window.open(
                "https://cardano.ideascale.com/c/idea/107662",
                "_blank",
                "noreferrer"
              )
            }
          />
        </Layout.Group>
        <Layout.Separator />
        <Layout.Close>
          <Flex.Row>
            <ButtonTransparent
              style={{ color: "currentColor" }}
              icon={<MdClose size="24px" />}
              onClick={onClose}
            />
          </Flex.Row>
        </Layout.Close>
        {!!onNavigateLeft && (
          <Layout.LeftArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowBackIosNew size="24px" />}
              onClick={onNavigateLeft}
            />
          </Layout.LeftArrow>
        )}
        {!!onNavigateRight && (
          <Layout.RightArrow>
            <ButtonTransparent
              className={styles.buttonNavigate}
              style={{ color: "currentColor" }}
              icon={<MdOutlineArrowForwardIos size="24px" />}
              onClick={onNavigateRight}
            />
          </Layout.RightArrow>
        )}
      </Layout.Root>
    </div>
  );
}
